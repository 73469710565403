@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
.app {
  --primary: #00a1c2;
  --default: #4d4d4d;
  --red: #f33207;
  --green: #009c00;
  --blue: #0033ff;
  --muted: #cdcdcd;
  --color: #191a1b;
  --background-color: #fafafa;
  --box-shadow__color: rgba(0, 0, 0, 0.1019607843);
  --header__background-color: #fafafa;
  --footer__color: #191a1b;
  --footer__background-color: #fafafa;
  --a__color: #00a1c2;
  --a__color--active: #008ca9;
  --button-color-primary: #00a1c2;
  --button-color-primary--hover: #00cbf5;
  --button-color-default: #4d4d4d;
  --button-color-default--hover: #676767;
  --button-color-gray: gray;
  --button-color-gray--hover: #9a9a9a;
  --button-color-red: #f33207;
  --button-color-red--hover: #f95834;
  --button-color-green: #009c00;
  --button-color-green--hover: #00cf00;
  --button-color-blue: #0033ff;
  --button-color-blue--hover: #335cff;
}

.app.dark {
  --primary: #00bfe6;
  --default: #b3b3b3;
  --red: #f7382b;
  --green: #2daf21;
  --blue: #2744ec;
  --muted: #4d4d4d;
  --color: #e9e9e9;
  --background-color: #191a1b;
  --box-shadow__color: rgba(0, 0, 0, 0.5019607843);
  --header__background-color: #191a1b;
  --footer__color: #e9e9e9;
  --footer__background-color: #191a1b;
  --a__color: #00bfe6;
  --a__color--active: #0095b3;
  --button-color-primary: #00bfe6;
  --button-color-primary--hover: #01d4ff;
  --button-color-default: #b3b3b3;
  --button-color-default--hover: silver;
  --button-color-gray: gray;
  --button-color-gray--hover: #8d8d8d;
  --button-color-red: #f7382b;
  --button-color-red--hover: #f84f44;
  --button-color-green: #2daf21;
  --button-color-green--hover: #33c425;
  --button-color-blue: #2744ec;
  --button-color-blue--hover: #3e58ee;
}

body {
  margin: 0;
  padding: 0;
  font-family: "roboto", sans-serif;
  font-weight: 400;
  line-height: 1.75;
}

.app {
  color: var(--color);
  background-color: var(--background-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

html {
  font-size: 16px;
} /*16px*/
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
  margin: 3rem 0 1.38rem;
  font-weight: 400;
  line-height: 1.3;
  font-family: "lato", sans-serif;
}

p {
  line-height: 1.6;
}

h1,
.h1 {
  margin-top: 0;
  font-size: 2.488rem;
}

h2,
.h2 {
  font-size: 2.074rem;
}

h3,
.h3 {
  font-size: 1.728rem;
}

h4,
.h4 {
  font-size: 1.44rem;
}

h5,
.h5 {
  font-size: 1.2rem;
}

p,
.p {
  font-size: 1rem;
  margin-bottom: 1rem;
}

small,
.text_small {
  font-size: 0.833rem;
}

@media (min-width: 768px) {
  h1,
  .h1 {
    margin-top: 0;
    font-size: 3.052rem;
  }
  h2,
  .h2 {
    font-size: 2.441rem;
  }
  h3,
  .h3 {
    font-size: 1.953rem;
  }
  h4,
  .h4 {
    font-size: 1.563rem;
  }
  h5,
  .h5 {
    font-size: 1.25rem;
  }
  p,
  .p {
    margin-bottom: 1rem;
    margin-bottom: 1rem;
  }
  small,
  .text_small {
    font-size: 0.8rem;
  }
}
a {
  color: var(--a__color);
  text-decoration: none;
}
a.active {
  color: var(--primary);
}
a:active, a:hover {
  color: var(--a__color--active);
}

.text-muted {
  color: var(--muted);
}

.text-center {
  text-align: center;
}

.box-shadow {
  box-shadow: 0 0 20px var(--box-shadow__color);
}

.dropdown {
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-color: var(--button-color-default);
  border-radius: 3px;
  line-height: 1;
  padding: 0;
}
.dropdown:hover, .dropdown:active, .dropdown--active {
  box-shadow: 0px 0px 1px 1px var(--box-shadow__color);
}
.dropdown:hover::after, .dropdown:active::after, .dropdown--active::after {
  border-top-color: var(--button-color-default);
}
.dropdown--disabled {
  opacity: 0.5;
}
.dropdown--disabled button:disabled {
  opacity: 1;
}
.dropdown--disabled:hover, .dropdown--disabled:active {
  background-color: transparent;
  box-shadow: none;
}
.dropdown--disabled:hover::after, .dropdown--disabled:active::after {
  border-top-color: var(--muted);
}
.dropdown__placeholder {
  color: var(--muted);
}
.dropdown--block {
  width: 100%;
}
.dropdown--block .dropdown__menu {
  width: 100%;
}
.dropdown__input {
  background-color: transparent;
  border: 0px solid transparent;
  text-align: left;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color);
  width: 100%;
}
.dropdown__input::after {
  display: block;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--muted);
  margin-left: 3px;
}
.dropdown__menu {
  list-style-type: decimal;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0;
  background-color: var(--background-color);
  display: grid;
  position: absolute;
  border-radius: 3px;
  box-shadow: 3px 3px 12px var(--box-shadow__color);
  margin-top: 1px;
  z-index: 1;
}
.dropdown__item {
  border: 0px;
  text-align: left;
  margin: 2px 0;
  color: var(--color);
  line-height: 1.6;
  background-color: transparent;
}
.dropdown__item:hover {
  background-color: var(--muted);
}
.dropdown__item--active, .dropdown__item--active:hover {
  background-color: var(--primary);
  color: white;
}

.dropdown--sm {
  min-width: 80px;
}
.dropdown--sm .dropdown__input {
  padding: 2px 5px;
  font-size: 0.833rem;
}
@media (min-width: 768px) {
  .dropdown--sm .dropdown__input {
    font-size: 0.8rem;
  }
}
.dropdown--sm .dropdown__input::after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid var(--muted);
}
.dropdown--sm .dropdown__menu {
  padding: 2px 0;
}
.dropdown--sm .dropdown__item {
  padding: 2px 5px;
  font-size: 0.833rem;
}
@media (min-width: 768px) {
  .dropdown--sm .dropdown__item {
    font-size: 0.8rem;
  }
}

.dropdown--md {
  min-width: 100px;
}
.dropdown--md .dropdown__input {
  padding: 5px 12px;
  font-size: 1rem;
}
@media (min-width: 768px) {
  .dropdown--md .dropdown__input {
    font-size: 1rem;
  }
}
.dropdown--md .dropdown__input::after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--muted);
}
.dropdown--md .dropdown__menu {
  padding: 5px 0;
}
.dropdown--md .dropdown__item {
  padding: 4px 8px;
  font-size: 1rem;
}
@media (min-width: 768px) {
  .dropdown--md .dropdown__item {
    font-size: 1rem;
  }
}

.dropdown--lg {
  min-width: 160px;
}
.dropdown--lg .dropdown__input {
  padding: 8px 20px;
  font-size: 1.2rem;
}
@media (min-width: 768px) {
  .dropdown--lg .dropdown__input {
    font-size: 1.25rem;
  }
}
.dropdown--lg .dropdown__input::after {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid var(--muted);
}
.dropdown--lg .dropdown__menu {
  padding: 8px 0;
}
.dropdown--lg .dropdown__item {
  padding: 4px 8px;
  font-size: 1.2rem;
}
@media (min-width: 768px) {
  .dropdown--lg .dropdown__item {
    font-size: 1.25rem;
  }
}

.icon {
  fill: currentColor;
  display: inline-block;
  vertical-align: middle;
}

.icon {
  width: 24px;
  height: 24px;
}

.btn,
button {
  display: inline-block;
  border-radius: 3px;
  font-family: inherit;
  width: auto;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  line-height: 1;
  touch-action: manipulation;
  background-image: none;
  border-style: solid;
  border-width: 1px;
}
.btn:focus, .btn:hover,
button:focus,
button:hover {
  outline: none;
}
.btn:disabled,
button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.btn-sm {
  padding: 2px 5px;
  font-size: 0.833rem;
}
@media (min-width: 768px) {
  .btn-sm {
    font-size: 0.8rem;
  }
}
.btn-sm .icon {
  width: 20px;
  height: 20px;
}

.btn-md {
  padding: 5px 12px;
  font-size: 1rem;
}
@media (min-width: 768px) {
  .btn-md {
    font-size: 1rem;
  }
}
.btn-md .icon {
  width: 24px;
  height: 24px;
}

.btn-lg {
  padding: 8px 20px;
  font-size: 1.2rem;
}
@media (min-width: 768px) {
  .btn-lg {
    font-size: 1.25rem;
  }
}
.btn-lg .icon {
  width: 28px;
  height: 28px;
}

.btn-icon {
  line-height: 0;
  padding: 0;
}

.btn-group {
  display: flex;
}
.btn-group .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group .btn:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group .btn:not(:first-child) {
  margin-left: -1px;
}
.btn-group .btn-block {
  flex: 1;
}

.btn-block {
  width: 100%;
}

.btn-solid-primary {
  border-color: transparent;
  background-color: var(--button-color-primary);
  color: white;
}
.btn-solid-primary:hover {
  background-color: var(--button-color-primary--hover);
  color: white;
}
.btn-solid-primary:active {
  background-color: var(--button-color-primary);
  color: white;
}

.btn-solid-default {
  border-color: transparent;
  background-color: var(--button-color-default);
  color: white;
}
.btn-solid-default:hover {
  background-color: var(--button-color-default--hover);
  color: white;
}
.btn-solid-default:active {
  background-color: var(--button-color-default);
  color: white;
}

.btn-solid-gray {
  border-color: transparent;
  background-color: var(--button-color-gray);
  color: white;
}
.btn-solid-gray:hover {
  background-color: var(--button-color-gray--hover);
  color: white;
}
.btn-solid-gray:active {
  background-color: var(--button-color-gray);
  color: white;
}

.btn-solid-red {
  border-color: transparent;
  background-color: var(--button-color-red);
  color: white;
}
.btn-solid-red:hover {
  background-color: var(--button-color-red--hover);
  color: white;
}
.btn-solid-red:active {
  background-color: var(--button-color-red);
  color: white;
}

.btn-solid-green {
  border-color: transparent;
  background-color: var(--button-color-green);
  color: white;
}
.btn-solid-green:hover {
  background-color: var(--button-color-green--hover);
  color: white;
}
.btn-solid-green:active {
  background-color: var(--button-color-green);
  color: white;
}

.btn-solid-blue {
  border-color: transparent;
  background-color: var(--button-color-blue);
  color: white;
}
.btn-solid-blue:hover {
  background-color: var(--button-color-blue--hover);
  color: white;
}
.btn-solid-blue:active {
  background-color: var(--button-color-blue);
  color: white;
}

.btn-outline-primary {
  border-color: var(--button-color-primary);
  color: var(--button-color-primary);
  background-color: transparent;
}
.btn-outline-primary:hover {
  background-color: rgba(128, 128, 128, 0.1019607843);
  color: var(--button-color-primary--hover);
}
.btn-outline-primary:active {
  background-color: transparent;
}

.btn-outline-default {
  border-color: var(--button-color-default);
  color: var(--button-color-default);
  background-color: transparent;
}
.btn-outline-default:hover {
  background-color: rgba(128, 128, 128, 0.1019607843);
  color: var(--button-color-default--hover);
}
.btn-outline-default:active {
  background-color: transparent;
}

.btn-outline-gray {
  border-color: var(--button-color-gray);
  color: var(--button-color-gray);
  background-color: transparent;
}
.btn-outline-gray:hover {
  background-color: rgba(128, 128, 128, 0.1019607843);
  color: var(--button-color-gray--hover);
}
.btn-outline-gray:active {
  background-color: transparent;
}

.btn-outline-red {
  border-color: var(--button-color-red);
  color: var(--button-color-red);
  background-color: transparent;
}
.btn-outline-red:hover {
  background-color: rgba(128, 128, 128, 0.1019607843);
  color: var(--button-color-red--hover);
}
.btn-outline-red:active {
  background-color: transparent;
}

.btn-outline-green {
  border-color: var(--button-color-green);
  color: var(--button-color-green);
  background-color: transparent;
}
.btn-outline-green:hover {
  background-color: rgba(128, 128, 128, 0.1019607843);
  color: var(--button-color-green--hover);
}
.btn-outline-green:active {
  background-color: transparent;
}

.btn-outline-blue {
  border-color: var(--button-color-blue);
  color: var(--button-color-blue);
  background-color: transparent;
}
.btn-outline-blue:hover {
  background-color: rgba(128, 128, 128, 0.1019607843);
  color: var(--button-color-blue--hover);
}
.btn-outline-blue:active {
  background-color: transparent;
}

.btn-link-primary {
  border-color: transparent;
  background-color: transparent;
  color: var(--button-color-primary);
}
.btn-link-primary:hover {
  color: var(--button-color-primary--hover);
}
.btn-link-primary:active {
  color: var(--button-color-primary);
}

.btn-link-default {
  border-color: transparent;
  background-color: transparent;
  color: var(--button-color-default);
}
.btn-link-default:hover {
  color: var(--button-color-default--hover);
}
.btn-link-default:active {
  color: var(--button-color-default);
}

.btn-link-gray {
  border-color: transparent;
  background-color: transparent;
  color: var(--button-color-gray);
}
.btn-link-gray:hover {
  color: var(--button-color-gray--hover);
}
.btn-link-gray:active {
  color: var(--button-color-gray);
}

.btn-link-red {
  border-color: transparent;
  background-color: transparent;
  color: var(--button-color-red);
}
.btn-link-red:hover {
  color: var(--button-color-red--hover);
}
.btn-link-red:active {
  color: var(--button-color-red);
}

.btn-link-green {
  border-color: transparent;
  background-color: transparent;
  color: var(--button-color-green);
}
.btn-link-green:hover {
  color: var(--button-color-green--hover);
}
.btn-link-green:active {
  color: var(--button-color-green);
}

.btn-link-blue {
  border-color: transparent;
  background-color: transparent;
  color: var(--button-color-blue);
}
.btn-link-blue:hover {
  color: var(--button-color-blue--hover);
}
.btn-link-blue:active {
  color: var(--button-color-blue);
}

.toggle-switch {
  position: relative;
  display: flex;
  align-items: center;
}
.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.toggle-switch__slider {
  position: relative;
  cursor: pointer;
  background-color: var(--muted);
  transition: 0.3s;
  border-radius: 7px;
  width: 26px;
  height: 14px;
}
.toggle-switch__slider:before {
  position: absolute;
  content: "";
  border-radius: 5px;
  height: 10px;
  width: 10px;
  left: 2px;
  bottom: 2px;
  background-color: var(--background-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.toggle-switch input:checked + .toggle-switch__slider:before {
  left: 14px;
}
.toggle-switch__label {
  margin-left: 4px;
  line-height: 1.6;
  font-size: 1rem;
}
@media (min-width: 768px) {
  .toggle-switch__label {
    font-size: 1rem;
  }
}

.toggle-switch--primary input:checked + .toggle-switch__slider {
  background-color: var(--button-color-primary);
  border-color: var(--button-color-primary);
}

.toggle-switch--default input:checked + .toggle-switch__slider {
  background-color: var(--button-color-default);
  border-color: var(--button-color-default);
}

.toggle-switch--red input:checked + .toggle-switch__slider {
  background-color: var(--button-color-red);
  border-color: var(--button-color-red);
}

.toggle-switch--green input:checked + .toggle-switch__slider {
  background-color: var(--button-color-green);
  border-color: var(--button-color-green);
}

.toggle-switch--blue input:checked + .toggle-switch__slider {
  background-color: var(--button-color-blue);
  border-color: var(--button-color-blue);
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  display: grid;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.768627451);
}
.lightbox__button {
  cursor: pointer;
}
.lightbox__button img {
  max-width: 100%;
}
.lightbox__inner {
  place-self: center;
}
.lightbox__inner img {
  max-width: 90vw;
  max-height: 90vh;
}

.table {
  width: 100%;
  position: relative;
  border-spacing: 0;
}
.table__header {
  background-color: var(--muted);
}
.table__header td {
  align-self: end;
  font-weight: 500;
  font-size: 1em;
}
.table__header button {
  background-color: transparent;
  padding: 3px 12px;
  font-weight: 500;
  font-size: 1em;
  border: 0px;
  text-align: left;
}
.table__header button:active {
  background-color: transparent;
  background-image: none;
  box-shadow: none;
}
.table__header button .icon {
  height: 16px;
  width: 16px;
  margin-right: 4px;
  transition: transform 0.2s ease-in-out;
}
.table__header button .desc {
  transform: rotate(-180deg);
}
.table tr {
  display: grid;
  grid-template-rows: auto min-content;
  align-items: center;
}
.table tr::after {
  content: "";
  display: block;
  height: 1px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), var(--muted), var(--muted), rgba(255, 255, 255, 0));
  grid-column: 1/-1;
  grid-row: -2/-1;
}
.table tr .btn-icon {
  padding: 0;
}
.table td {
  padding: 3px 12px;
  word-break: break-word;
}

.tabs {
  width: 100%;
  margin: 0 auto;
}
.tabs__header {
  display: flex;
  border-bottom: 2px solid var(--muted);
}
.tabs__tab {
  background: transparent;
  border: 0px;
  color: var(--color);
  padding: 8px 12px;
  border-radius: 0;
}
.tabs__tab:hover {
  color: var(--button-color-red--hover);
}
.tabs__tab--selected {
  color: var(--button-color-red);
  border-bottom: 2px solid var(--button-color-red);
  margin-bottom: -2px;
}
.tabs__content {
  background-color: rgba(128, 128, 128, 0.2039215686);
  padding: 8px;
  border-radius: 0 0 4px 4px;
}

.tabs--primary .tabs__tab {
  color: gray;
  padding: 8px 12px;
  border-radius: 0;
}
.tabs--primary .tabs__tab:hover:not(:disabled) {
  color: var(--button-color-primary--hover);
}
.tabs--primary .tabs__tab--selected {
  color: var(--button-color-primary);
  border-bottom: 2px solid var(--button-color-primary);
  margin-bottom: -2px;
}

.tabs--default .tabs__tab {
  color: gray;
  padding: 8px 12px;
  border-radius: 0;
}
.tabs--default .tabs__tab:hover:not(:disabled) {
  color: var(--button-color-default--hover);
}
.tabs--default .tabs__tab--selected {
  color: var(--button-color-default);
  border-bottom: 2px solid var(--button-color-default);
  margin-bottom: -2px;
}

.tabs--gray .tabs__tab {
  color: gray;
  padding: 8px 12px;
  border-radius: 0;
}
.tabs--gray .tabs__tab:hover:not(:disabled) {
  color: var(--button-color-gray--hover);
}
.tabs--gray .tabs__tab--selected {
  color: var(--button-color-gray);
  border-bottom: 2px solid var(--button-color-gray);
  margin-bottom: -2px;
}

.tabs--red .tabs__tab {
  color: gray;
  padding: 8px 12px;
  border-radius: 0;
}
.tabs--red .tabs__tab:hover:not(:disabled) {
  color: var(--button-color-red--hover);
}
.tabs--red .tabs__tab--selected {
  color: var(--button-color-red);
  border-bottom: 2px solid var(--button-color-red);
  margin-bottom: -2px;
}

.tabs--green .tabs__tab {
  color: gray;
  padding: 8px 12px;
  border-radius: 0;
}
.tabs--green .tabs__tab:hover:not(:disabled) {
  color: var(--button-color-green--hover);
}
.tabs--green .tabs__tab--selected {
  color: var(--button-color-green);
  border-bottom: 2px solid var(--button-color-green);
  margin-bottom: -2px;
}

.tabs--blue .tabs__tab {
  color: gray;
  padding: 8px 12px;
  border-radius: 0;
}
.tabs--blue .tabs__tab:hover:not(:disabled) {
  color: var(--button-color-blue--hover);
}
.tabs--blue .tabs__tab--selected {
  color: var(--button-color-blue);
  border-bottom: 2px solid var(--button-color-blue);
  margin-bottom: -2px;
}

.tabs--sm .tabs__tab {
  padding: 2px 5px;
  font-size: 0.833rem;
}
@media (min-width: 768px) {
  .tabs--sm .tabs__tab {
    font-size: 0.8rem;
  }
}

.tabs--md .tabs__tab {
  padding: 5px 12px;
  font-size: 1rem;
}
@media (min-width: 768px) {
  .tabs--md .tabs__tab {
    font-size: 1rem;
  }
}

.tabs--lg .tabs__tab {
  padding: 8px 20px;
  font-size: 1.2rem;
}
@media (min-width: 768px) {
  .tabs--lg .tabs__tab {
    font-size: 1.25rem;
  }
}

.photo-slider {
  position: relative;
  display: grid;
  width: 100%;
}
.photo-slider > * {
  grid-area: 1/1/2/2;
  display: grid;
}
.photo-slider__left {
  overflow: hidden;
  border-right: 2px solid var(--muted);
}
.photo-slider__slider {
  z-index: 100;
  width: 100%;
  height: 100%;
  place-self: center;
  -webkit-appearance: none;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.photo-slider input[type=range]::-webkit-slider-runnable-track {
  margin: 0 -5px;
}
.photo-slider input[type=range]::-moz-range-track {
  margin: 0 -5px;
}
.photo-slider input[type=range]::-ms-track {
  margin: 0 -5px;
}
.photo-slider input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 4px solid white;
  height: 36px;
  width: 10px;
  border-radius: 3px;
  background: var(--primary);
  cursor: ew-resize;
  box-shadow: 0 0 0 1pt var(--muted);
}
.photo-slider input[type=range]::-ms-thumb {
  -webkit-appearance: none;
  border: 4px solid white;
  height: 36px;
  width: 10px;
  border-radius: 3px;
  background: var(--primary);
  cursor: ew-resize;
  box-shadow: 0 0 0 1pt var(--muted);
}
.photo-slider input[type=range]::-moz-range-thumb {
  height: 100%;
  width: 4px;
  border: 0px;
  background-color: var(--muted);
  cursor: ew-resize;
}

.main-header {
  background-color: var(--background-color);
  position: fixed;
  width: 100%;
  transition: all 0.5s ease-in-out;
  z-index: 300;
  touch-action: manipulation;
  font-size: 22px;
}
@media (min-width: 768px) {
  .main-header {
    font-size: 28px;
  }
}
.main-header::after {
  content: "";
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 20px var(--box-shadow__color);
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.main-header--scroll {
  font-size: 22px;
  background-color: var(--header__background-color);
}
.main-header--scroll::after {
  opacity: 1;
}
.main-header__wrapper {
  max-width: 1200px;
  margin: auto;
  padding: 0 0.5em;
  display: flex;
  place-content: space-between;
  align-items: center;
}
@media (min-width: 768px) {
  .main-header__wrapper {
    margin: 0em auto;
  }
}
@media (min-width: 768px) {
  .main-header__mobile-nav-button {
    display: none;
  }
}
.main-header__logo {
  color: var(--primary);
  text-transform: uppercase;
  font-size: 1.4em;
  font-weight: 800;
}
.main-header__logo:hover, .main-header__logo:active {
  color: var(--primary);
}
.main-header__desktop-nav {
  display: flex;
  align-items: center;
}
.main-header__desktop-nav a {
  display: none;
  color: var(--default);
}
.main-header__desktop-nav a.active {
  color: var(--primary);
}
@media (min-width: 768px) {
  .main-header__desktop-nav > * {
    margin-left: 1em;
  }
  .main-header__desktop-nav a {
    display: inline-block;
  }
}
.main-header__mobile-nav {
  display: grid;
  padding: 0 1em;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}
.main-header__mobile-nav--show {
  max-height: 1000px;
}
@media (min-width: 768px) {
  .main-header__mobile-nav {
    display: none;
  }
}
.main-header__mobile-nav a:first-child {
  margin-top: 0.5em;
}
.main-header__mobile-nav a:last-child {
  margin-bottom: 0.5em;
}
.main-header .switch {
  place-self: center;
}
.main-header .switch input:checked + .slider {
  background-color: var(--a__background-color);
}

.header__pseudo {
  height: 80px;
  display: block;
}

.footer {
  width: 100%;
  z-index: 300;
  margin-top: 3em;
  color: var(--footer__color);
  background-color: var(--footer__background-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.footer__social {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer__social > * {
  margin: 0 5px;
}
.footer__disclaimer {
  color: var(--muted);
  line-height: 3;
}

.about {
  padding: 0 2em;
}

.profile {
  max-width: 900px;
  margin: 0 auto;
  display: grid;
}
@media (min-width: 768px) {
  .profile {
    padding: 3em;
    justify-content: left;
    grid-gap: 0 20px;
    grid-template-columns: minmax(300px, 0.5fr) auto;
    grid-template-rows: 1fr repeat(3, min-content) 1fr;
  }
}
.profile img {
  width: 100%;
  place-self: center;
  height: intrinsic;
  max-width: 250px;
}
@media (min-width: 768px) {
  .profile img {
    grid-row: 1/6;
    max-width: 100%;
  }
}
.profile h1 {
  grid-row: 1/2;
  margin: 0;
  text-align: center;
  font-size: 1.728rem;
}
@media (min-width: 768px) {
  .profile h1 {
    grid-row: 2/3;
    text-align: left;
    font-size: 2.488rem;
  }
}
.profile h2 {
  grid-row: 2/3;
  line-height: 1.2;
  text-align: center;
  font-size: 1.728rem;
}
@media (min-width: 768px) {
  .profile h2 {
    grid-row: 3/4;
    text-align: left;
    font-size: 2.488rem;
  }
}
.profile h2 a {
  color: var(--color);
  text-decoration: underline;
  text-decoration-thickness: 3px;
}
.profile h2 a:nth-child(1) {
  text-decoration-color: var(--button-color-red);
}
.profile h2 a:nth-child(1):hover {
  color: var(--button-color-red);
}
.profile h2 a:nth-child(2) {
  text-decoration-color: var(--button-color-green);
}
.profile h2 a:nth-child(2):hover {
  color: var(--button-color-green);
}
.profile h2 a:nth-child(3) {
  text-decoration-color: var(--button-color-blue);
}
.profile h2 a:nth-child(3):hover {
  color: var(--button-color-blue);
}
.profile p {
  grid-row: 4/5;
  font-size: 1rem;
}
@media (min-width: 768px) {
  .profile p {
    margin: 0;
    max-width: 550px;
    font-size: 1.44rem;
  }
}

.project {
  flex: 1;
}

.project-header {
  max-width: 900px;
  margin: 0 auto;
  padding: 0 1em;
}
.project-header__title {
  margin: 0;
  line-height: 1;
}
.project-header__tags .btn {
  text-transform: uppercase;
}

.project-body {
  max-width: 1200px;
  margin: 0 auto;
}

.project-section {
  max-width: 900px;
  margin: 0 auto 2em;
  padding: 0 1em;
}

.iconography-header {
  display: grid;
  grid-gap: 2em;
  place-items: center;
}
@media (min-width: 992px) {
  .iconography-header {
    grid-template-columns: 2fr 3fr;
  }
}
.iconography-header svg {
  fill: currentColor;
  max-height: 400px;
}

.icongraphy-section:not(:last-child):after {
  content: "";
  display: block;
  height: 1px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(128, 128, 128, 0.5019607843), rgba(128, 128, 128, 0.5019607843), rgba(255, 255, 255, 0));
  grid-column: 1/-1;
  grid-row: -2/-1;
}

.iconography-grid {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 24px;
  padding: 1em 0 2em;
}
.iconography-grid h3,
.iconography-grid p {
  grid-column: 1/-1;
  margin: 0;
  text-align: center;
}
.iconography-grid h3 {
  margin-bottom: 0.5em;
}
.iconography-grid__icon {
  display: grid;
  align-items: flex-start;
  grid-template-rows: min-content;
  justify-items: center;
  text-align: center;
}
.iconography-grid__icon .icon {
  height: 36px;
  width: 36px;
  margin-top: 20px;
}
.iconography-grid__icon p {
  line-height: 1.2;
  font-weight: 300;
  font-size: 0.9em;
  margin: 0.75em 0;
}

.praise-hk {
  display: grid;
  max-width: 900px;
}
.praise-hk img {
  max-width: 100%;
  max-height: 100%;
}
.praise-hk img:first-child {
  border-radius: 3px 3px 0 0;
}
.praise-hk img:last-child {
  border-bottom: 1px solid black;
}

.spooning-spoon__poster {
  display: flex;
  place-content: center;
}
@media (min-width: 768px) {
  .spooning-spoon__definition {
    display: flex;
    flex-direction: row-reverse;
  }
}
.spooning-spoon__definition h4 {
  margin: 1em 0 0 0;
}

.portfolio {
  padding: 1em;
}

.component-docs {
  max-width: 900px;
  border: 1px solid var(--muted);
  padding: 0;
  border-radius: 3px;
}
.component-docs__header {
  padding: 8px 24px;
  margin: 0;
  background-color: var(--muted);
  font-weight: 600;
  color: var(--color);
}
.component-docs__example {
  border-radius: 3px 3px 0 0;
  padding: 16px 24px;
  display: flex;
  align-items: center;
}
.component-docs__example--button {
  min-height: 50px;
}
.component-docs__buttons {
  display: flex;
  padding: 16px 24px;
  border-top: 1px solid var(--muted);
  justify-content: space-between;
  flex-wrap: wrap;
}
.component-docs__options {
  display: flex;
  flex-wrap: wrap;
}
.component-docs__options > * {
  margin-right: 10px;
}
.component-docs__code {
  border-top: 1px solid var(--muted);
  padding: 16px 24px !important;
  margin: 0 !important;
}

.preview-wrapper {
  margin: 0 auto;
  position: relative;
}
.preview-wrapper--desktop {
  max-width: 900px;
}
.preview-wrapper--desktop::before, .preview-wrapper--desktop::after {
  content: "";
  display: block;
  height: 12px;
  width: 12px;
  background-color: rgba(48, 48, 48, 0.5019607843);
  z-index: 3;
  border-radius: 50%;
  position: absolute;
  top: 10px;
}
.preview-wrapper--desktop::before {
  left: 14px;
  background-color: var(--red);
}
.preview-wrapper--desktop::after {
  left: 35px;
  background-color: var(--green);
}
.preview-wrapper--mobile {
  max-width: 300px;
}
.preview-wrapper--mobile::before, .preview-wrapper--mobile::after {
  content: "";
  display: block;
  background-color: rgba(48, 48, 48, 0.5019607843);
  z-index: 3;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
}
.preview-wrapper--mobile::before {
  height: 4px;
  width: 50px;
  border-radius: 2px;
  top: 6px;
}
.preview-wrapper--mobile::after {
  height: 30px;
  width: 30px;
  z-index: 3;
  border-radius: 50%;
  bottom: 5px;
}

.preview-background {
  height: 0;
  overflow: hidden;
  position: relative;
  background-color: rgba(179, 179, 179, 0.4509803922);
  border-color: transparent;
  border-style: solid;
}
.preview-background--desktop {
  padding-top: 60%;
  border-width: 0px;
  border-radius: 4px 4px 4px 4px;
}
.preview-background--mobile {
  padding-top: 200%;
  border-width: 18px 4px 40px;
  border-radius: 8px;
}

.preview-selector {
  position: absolute;
  top: 0;
  background-color: transparent;
}
.preview-selector--desktop {
  width: 75%;
  padding: 5px;
  left: 50%;
  transform: translateX(-50%);
}
.preview-selector--desktop .btn-group .btn {
  border: transparent;
  margin-left: 1px;
  padding: 3px;
}
.preview-selector--desktop .btn-group .btn:not(.btn-solid-default) {
  background-color: white;
  color: rgba(0, 0, 0, 0.7529411765);
}
.preview-selector--desktop .btn-group .btn-solid-default {
  background-color: rgba(164, 164, 164, 0.7215686275);
}
.preview-selector--mobile {
  width: 100%;
}
.preview-selector--mobile .dropdown {
  background-color: var(--background-color);
  border: 0px solid white;
  border-radius: 2px;
}
.preview-selector--mobile .dropdown__input::after {
  border-top: 5px solid var(--color);
}

.preview-container {
  position: absolute;
  top: 32px;
  bottom: -11px;
  left: 0;
  right: 0;
  overflow-y: scroll;
  max-height: 100%;
}
.preview-container img {
  max-width: 100%;
}

.project-index {
  padding: 1em;
}
.project-index__title {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.project-index__grid {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
}
@media (min-width: 768px) {
  .project-index__grid {
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    grid-gap: 2em;
  }
}

.project-preview {
  display: grid;
  align-items: center;
  overflow: hidden;
  transition: 0.25s ease-in-out;
  padding: 2em 0;
}
.project-preview:not(:last-child) {
  border-bottom: 1px solid var(--muted);
}
@media (min-width: 768px) {
  .project-preview {
    border-radius: 4px;
    grid-template-rows: 1fr repeat(3, min-content) 1fr;
    padding: 0;
  }
  .project-preview:not(:last-child) {
    border-bottom: 0px;
  }
}
.project-preview__img {
  display: grid;
  grid-column: 1/2;
  grid-row: 1/2;
}
.project-preview__img img {
  width: 100%;
  place-self: center;
  z-index: 1;
}
@media (min-width: 768px) {
  .project-preview__img {
    grid-area: 1/1/6/2;
  }
}
.project-preview__link {
  grid-area: 1/1/5/2;
  width: 100%;
  height: 100%;
  z-index: 3;
}
@media (min-width: 768px) {
  .project-preview__link {
    grid-area: 1/1/6/2;
  }
}
.project-preview::before {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1;
  grid-area: 1/1/2/2;
}
@media (min-width: 768px) {
  .project-preview::before {
    grid-area: 1/1/6/2;
  }
}
.project-preview:hover h3,
.project-preview:hover p, .project-preview--under-construction h3,
.project-preview--under-construction p {
  z-index: 2;
}
.project-preview:hover .project-preview__tags, .project-preview--under-construction .project-preview__tags {
  z-index: 4;
}
@media (min-width: 768px) {
  .project-preview:hover::after, .project-preview--under-construction::after {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.75;
    grid-area: 1/1/6/2;
    z-index: 1;
  }
}
.project-preview h3,
.project-preview p, .project-preview__tags {
  padding: 0 2em;
  grid-column: 1/2;
}
.project-preview h3 {
  text-align: center;
  margin: 0.5em 0 0;
  grid-row: 2/3;
}
@media (min-width: 768px) {
  .project-preview h3 {
    color: white;
  }
}
.project-preview p {
  grid-row: 3/4;
}
@media (min-width: 768px) {
  .project-preview p {
    color: white;
  }
}
.project-preview__tags {
  display: flex;
  flex-wrap: wrap;
  grid-row: 4/5;
  max-width: fit-content;
}
.project-preview__tags .btn {
  text-transform: uppercase;
  margin-right: 0.5em;
}

.resume {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px 40px;
}
@media (min-width: 768px) {
  .resume {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 992px) {
  .resume {
    grid-template-columns: 200px 1fr 1fr;
  }
}
.resume__logo {
  width: 50px;
}
@media (min-width: 768px) {
  .resume__logo {
    grid-column: 1/2;
  }
}
@media (min-width: 992px) {
  .resume__logo {
    grid-column: 1/4;
  }
}
.resume__profile h2 {
  margin: 0;
  font-size: 2.2em;
}
.resume__profile p {
  font-weight: 500;
  margin: 0;
}
@media (min-width: 768px) {
  .resume__profile {
    grid-column: 1/3;
  }
}
@media (min-width: 992px) {
  .resume__profile {
    grid-column: 1/2;
  }
}
.resume__section {
  margin-bottom: 2em;
}
.resume h3 {
  font-size: 2.2em;
  font-weight: 500;
  margin: 0;
  color: var(--primary);
}
.resume__item--open, .resume__item--closed {
  cursor: pointer;
}
.resume__item--open .resume__content, .resume__item--closed .resume__content {
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
}
.resume__item--closed .resume__content {
  max-height: 0;
}
.resume__item--open .icon {
  transform: rotate(-180deg);
}
.resume__item--open .resume__content {
  max-height: 2000px;
}
.resume h4 {
  margin: 0;
  margin-top: 0.5em;
  font-size: 1.2rem;
  font-weight: 700;
}
.resume__detail {
  display: flex;
  align-content: center;
}
.resume__detail h5,
.resume__detail p {
  margin: 0;
  line-height: 1.6;
  font-weight: 400;
  font-size: 1rem;
}
.resume__detail p::before {
  content: "";
  margin: 0 0.25em;
  background-color: gray;
  height: 1px;
  width: 10px;
  display: inline-block;
  vertical-align: middle;
}
.resume__detail .icon {
  height: 18px;
  width: 18px;
  color: var(--primary);
  margin-bottom: 3px;
  margin-left: 3px;
  place-self: flex-end;
  transition: transform 0.4s ease-in-out;
}
.resume__content span:not(:last-child)::after {
  content: "";
  background-color: gray;
  height: 2px;
  border-radius: 3px;
  width: 2px;
  display: inline-block;
  margin-bottom: -0.25em;
  margin: 0 0.5em;
  vertical-align: middle;
}
.resume__content p {
  margin: 0.5em 0;
  line-height: 1.4;
  font-weight: 300;
  opacity: 0.8;
}
.resume__content p:last-child {
  margin-bottom: 1.5em;
}

.four-oh-four {
  max-width: 1040px;
  margin: 0 auto auto;
  display: grid;
  justify-content: center;
}
.four-oh-four h1,
.four-oh-four h3,
.four-oh-four p,
.four-oh-four div {
  padding: 0 1em;
  text-align: center;
  margin: 0;
}
.four-oh-four img {
  margin: 2em 0;
  max-width: 100%;
  width: 500px;
  height: intrinsic;
  border-radius: 4px;
}
.four-oh-four div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.four-oh-four div > * {
  margin: 0.25em 0.5em;
}

/*# sourceMappingURL=abacus.css.map */
